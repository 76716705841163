.terminal {
  margin: 20px;
}


.landing-grid {
  margin: 0 0 0 0;
  padding: 0 0 0 0;
 text-align: center;
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: 0.8;
}

.landing-grid img {
  /* background: blue; */
 width: 400px;
  height: 400px;
  padding:0 0 0 0;
}

.avatar-img {
  height: 250px;
  padding-top:5em;
}

.banner-text {
  background-color: black;
  opacity: 0.8;
  width: 90%;
  margin: auto;
  border-radius: 10px;
}


.banner-text h1 {
  font-size: 66px;
  font-weight: bold;
  color: white;
}

@media only screen and (max-width: 600px) {
  .banner-text h1 {
  font-size: 32px;
  }
}

.banner-text hr {
  border-top: 5px dotted white;
  width: 50%;
  margin: auto;
  
}

.banner-text p {
  color: white;
  font-size:  20px;
  padding: 1em;
}

.social-links {
  display: flex;
  justify-content: space-around;
  width: 50%;
  margin:auto;
}


.social-links i {
  color: white;
  font-size: 5em;
  padding: 8px;
}

.big-content .material-icons {
  color: #88939c;
}


/* contact page css */

.contact-body {
  margin:auto;
  width: 100%;
  height: 100%;
  justify-content: center;
  padding-top: 2em;
}

.contact-grid {
  text-align: center;
  justify-content: center;
  width: 80%;
  height: 550px;
  background: white;
  color: black;
  margin-bottom: 20px;
}

.contact-grid h2 {
  font-family: 'Anton', sans-serif;
}

.contact-grid p {
  font-family: 'Oxygen', sans-serif;
  font-weight: bold;
}

.contact-grid hr {
  border-top: 5px dotted black;
  width: 50%;
  margin: auto;
}

.contact-list i {
  font-size: 50px;
  justify-content: center;
  text-align: center;
  color: black;
  opacity: 0.8;
  
}
/* contact page css -end */


/* Resume page */

.resume-right-col {
  background: #27221F;
  color: white;
  padding: 2em;
  margin: 0;
}

/* Resume page - end */



.mdl-layout__drawer-button {
  outline: none;
}